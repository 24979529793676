import React from 'react'
import styled from "styled-components"

import Layout from "../components/layouts/Layout"
import Seo from "../components/main/SEO"
import FormGR from "../components/forms/FormGR";

import SectionLayoutGrid from "../components/styled-components/wrappers/SectionLayoutGrid"
import Box from "../components/styled-components/speaker-template/Box"
import Col from "../components/styled-components/wrappers/Col"
import H2 from "../components/styled-components/typography/H2"
import Divider from "../components/styled-components/divider/Divider"
import BackgroundWrapper from "../components/styled-components/wrappers/BackgroundWrapper"
import P from "../components/styled-components/typography/P"

const ContactCard = styled.div`
  display: grid;
  padding: 40px 4% 40px 4%;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0dee9;
  background-color: #fff;
  text-align: center;
  align-self: stretch;
  height: 80%;
`

const ContactCardIMG = styled.img`
  max-width: 100%;
  height: 100px;
  margin-bottom: 16px;
  align-self: center;
  justify-self: center;
`

const ContactCardA = styled.a`
  color: #706b81;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const SocialIconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SocialIconsA = styled.a`
  padding: 8px;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  &:hover{
    opacity: 1;
  }
`

const ContactUs = () => {
    return (
        <Layout title={"Kapcsolat"}>
            <Seo title={"Balasys Blackowl - Kapcsolat"} description={"A Balasys Blackowl kapcsolat oldala."} keywords={"balasys, blackowl, kapcsolat"} url={"/contact-us"}/>
            <BackgroundWrapper>
                <SectionLayoutGrid alignStart >
                    <Col full grid marginBottom="small">
                        <Box noColumns>
                            <H2 medium>
                                Kérdésed van?
                            </H2>
                            <Divider/>
                            <FormGR
                                isContact
                            />
                        </Box>
                    </Col>
                    <Col full top center grid>
                        <H2 medium>Maradjunk kapcsolatban</H2>
                        <Divider center/>
                    </Col>
                    <Col col4 center marginBottom="small">
                        <ContactCard>
                            <ContactCardIMG src="/assets/location-icon.png" alt="location"/>
                            <H2 small>
                                Helyszín
                            </H2>
                            <Divider center/>
                            <P noMargin>Bárhol, ahol van internet</P>
                        </ContactCard>
                    </Col>
                    <Col col4 center marginBottom="small">
                        <ContactCard>
                            <ContactCardIMG src="/assets/contacts-icon.png" alt="contacts"/>
                            <H2 small>
                                Email
                            </H2>
                            <Divider center/>
                            <ContactCardA
                                href="mailto:info@balasys.hu">info@balasys.hu
                            </ContactCardA>
                        </ContactCard>
                    </Col>
                    <Col col4 center marginBottom="small">
                        <ContactCard>
                            <ContactCardIMG src="/assets/connect-icon.png" alt="connect"/>
                            <H2 small>
                                Kövess minket
                            </H2>
                            <Divider center/>
                            <SocialIconsWrapper>
                                <SocialIconsA href="https://www.facebook.com/balasysIT" target="_blank" rel="noreferrer"
                                   className="social-icon dark-icon w-inline-block">
                                    <img src="/assets/facebook-icon.svg" alt=""/>
                                </SocialIconsA>
                                <SocialIconsA href="https://www.youtube.com/channel/UCPs75gX3u4cXiXv_LFLKj-g"
                                   target="_blank" rel="noreferrer" className="social-icon dark-icon w-inline-block">
                                    <img src="/assets/youtube-icon.svg" alt=""/>
                                </SocialIconsA>
                            </SocialIconsWrapper>
                        </ContactCard>
                    </Col>
                </SectionLayoutGrid>
            </BackgroundWrapper>
        </Layout>
    );
};

export default ContactUs;